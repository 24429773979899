import Layout from "../components/layout"
import React from "react"
import SEO from "../components/seo"
import { graphql } from "gatsby"

const DisclaimerPage = ({ data }) => {
  const siteTitle = `Disclaimer`
  const email = data.site.siteMetadata?.email || `Email`
  const organization = data.site.siteMetadata?.organization || `Organization`
  const keywords = data.site.siteMetadata?.keywords || []

  return (
    <Layout email={email} organization={organization}>
      <SEO title={siteTitle} keywords={keywords} />
      <>
        <h2>Disclaimer for Peyda Track</h2>

        <p>
          We are doing our best to prepare the content of this app. However,
          Pixeesoft GmbH cannot warranty the expressions and suggestions of the
          contents, as well as its accuracy. In addition, to the extent
          permitted by the law, Peyda Track shall not be responsible for any
          losses and/or damages due to the usage of the information in our app.
        </p>

        <p>
          By using our app, you hereby consent to our disclaimer and agree to
          its terms.
        </p>

        <p>
          Any links contained in our app may lead to external sites are provided
          for convenience only. Any information or statements that appeared in
          these sites or app are not sponsored, endorsed, or otherwise approved
          by Peyda Track. For these external sites, Peyda Track cannot be held
          liable for the availability of, or the content located on or through
          it. Plus, any losses or damages occurred from using these contents or
          the internet generally.
        </p>
      </>
    </Layout>
  )
}

export default DisclaimerPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        email
        organization
        keywords
      }
    }
  }
`
